<template>
    <div class="news">
        <nav-bar class="nav-bar" id="titleArea" ref="nav"></nav-bar>
        <div class="news-content" id="middleArea">
            <h2>{{ teachVideo.title }}</h2>
            <div class="video">
                <div class="video-left">
                    <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                        :options="playerOptions"></video-player>
                </div>
                <div class="video-right" v-html='this.teachVideo.contents'>
                </div>
            </div>
        </div>
        <bottom-bar class="home-bottom" />
    </div>
</template>
<script>

import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import { GetVideo } from '@/api/news'
import { getInfo } from '@/api/login'

import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

import NavBar from '../../components/content/navBar/NavBar'
import BottomBar from '../../components/content/bottomBar/BottomBar'
import { getToken, setToken, removeToken } from '@/utils/cookies.js'

export default {
    name: 'News',
    components: {
        NavBar,
        BottomBar,
        videoPlayer
    },
    data() {
        return {
            users: {},//用户信息
            playerOptions: {

                playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度

                autoplay: false, //如果true,浏览器准备好时开始回放。

                muted: false, // 默认情况下将会消除任何音频。

                loop: false, // 导致视频一结束就重新开始。

                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）

                language: 'zh-CN',

                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）

                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。

                // sources: [{
                //     type: "video/mp4",
                //     src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4" //url地址
                // }],
                sources: [],
                poster: "", //你的封面地址
                // width: document.documentElement.clientWidth,

                notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。

                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true  //全屏按钮
                }

            },
            teachVideo: {},//详细信息
        }
    },
    created() {
        //用户信息
        this.userInfo();
        //详细信息
        this.onGetVideo();
    },
    methods: {
        //用户信息
        userInfo() {
            var token = getToken();
            if (token) {
                getInfo(token).then(response => {
                    if (response.status == 200) {
                        this.users = response.data;
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                })
            }
        },
        //详细信息
        onGetVideo() {
            this.listLoading = true
            this.id = this.$route.params.id;
            GetVideo({ 'id': this.id }).then(response => {
                if (response) {
                    if (response.status == 200) {
                        this.teachVideo = response.data;
                        let sources = [{
                            type: "video/" + this.teachVideo.videoType,
                            src: this.teachVideo.videoUrl //url地址
                        }];
                        this.playerOptions.sources = sources;
                        this.playerOptions.poster = this.teachVideo.imgUrl;

                    } else {
                        MessageBox.alert(response.message, "错误", {
                            confirmButtonText: '确定',
                            type: 'error'
                        })
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                }
            })
        },
    },
    mounted() {
        this.$refs.nav.mdd = 3;
        localStorage.setItem("mdd", 3);
        const titleHeight = document.getElementById('titleArea').clientHeight;
        const middleArea = document.getElementById('middleArea');
        const mheight = document.documentElement.clientHeight;
        middleArea.style.minHeight = (Number(mheight) - (Number(titleHeight) + 50)) + 'px';
    },
}
</script>
<style scoped lang="less">
.news {
    min-width: 1000px;

    .nav-bar {
        .bar-icon {
            border-radius: 50%;
            vertical-align: -4px;
            margin-right: 7px;
            width: 1.05rem;
            height: 1.05rem;
        }
    }

    .news-content {
        padding-left: 9.1rem;
        padding-right: 9.1rem;
        margin-top: 1.1rem;
        padding-bottom: 1.8rem;

        h2 {
            font-size: 24px;
            color: #000;
            text-align: center;
            font-weight: normal;
        }

        .video {
            display: flex;

            .video-left {
                flex: 1;
            }

            .video-right {
                width: 30%;
                padding: 0 10px;
                background-color: #fff;
                border: 1px solid #dadada;

                h3 {
                    font-weight: normal;
                    font-size: 16px;
                    margin: 10px 0;
                }

                p {
                    color: #999;
                    padding: 0 10px;
                    font-size: 14px;
                    margin: 0;
                }
            }
        }
    }
}
</style>
<style>
.video-left .video-js .vjs-big-play-button {
    border-width: 3px;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    line-height: 70px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
</style>